<template>
    <div :class="['scroll-tab', className('scroll-tab-')]" ref="scrollTab">
        <div class="scroll-tab-container">
            <div :class="['scroll-tab-item', { 'scroll-tab-item-active': active == key }]" v-for="(vo, key) in menu"
                @click="onClick(key)">
                {{ vo[field] }}
            </div>
        </div>
    </div>
</template>

<script>
import BScroll from 'better-scroll';

export default {
    inject: ['className'],
    props: {
        menu: {
            type: Array,
            default: []
        },
        active: {
            type: Number,
            default: 0
        },
        field: {
            type: String,
            default: 'title'
        }
    },
    data() {
        return {
            scrollTab: null,
        }
    },
    created() {
        this.$nextTick(() => {
            this.scrollTab = new BScroll(this.$refs.scrollTab, {
                click: true,
                bounce: false,
                scrollX: true,
            });
        });
    },
    watch: {
        menu() {
            this.$nextTick(() => {
                this.scrollTab.refresh();
            });
        }
    },
    methods: {
        onClick(key) {
            if (key != this.active) {
                this.scrollTab.scrollToElement(event.currentTarget, 300, true);
                this.$emit("click", key);
            }
        }
    }
}
</script>

<style lang="less" scoped>
.scroll-tab {
    height: 40px;
    overflow: hidden;
    white-space: nowrap;

    .scroll-tab-container {
        display: inline-block;

        .scroll-tab-item {
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
            margin-right: 20px;
            white-space: nowrap;
            padding: 8px 0 8px;

            &.scroll-tab-item-active {
                font-weight: bold;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 6px;
                    transform: translateX(-50%);
                    width: 25px;
                    height: 3px;
                    border-radius: 100px;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.scroll-tab.scroll-tab-city {
    .scroll-tab-item-active {
        color: #3377ff;

        &:after {
            background-color: #3377ff;
        }
    }
}


.scroll-tab.scroll-tab-district {
    .scroll-tab-item-active {
        color: #ff6969;

        &:after {
            background-color: #ff6969;
        }
    }
}
</style>
